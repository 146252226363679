import * as React from 'react';
import { Button } from '~/cds/Button';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '~/cds/Dialog';
import { cn } from '~/common/utils/styles';

type UseModalShowReturnType = {
    show: boolean;
    setShow: (value: boolean) => void;
    onHide: () => void;
};

const useModalShow = (): UseModalShowReturnType => {
    const [show, setShow] = React.useState(false);

    const handleOnHide = () => {
        setShow(false);
    };

    return {
        show,
        setShow,
        onHide: handleOnHide,
    };
};

type ModalContextType = {
    show: (content: AlertContent) => Promise<boolean>;
};

type ConfirmationModalProviderProps = {
    children: React.ReactNode;
};

const ConfirmationModalContext = React.createContext<ModalContextType>(
    {} as ModalContextType
);

const ConfirmationModalProvider: React.FC<ConfirmationModalProviderProps> = (
    props
) => {
    const { setShow, show, onHide } = useModalShow();
    const [content, setContent] = React.useState<AlertContent | null>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const resolver = React.useRef<any>();

    const handleShow = (content: AlertContent): Promise<boolean> => {
        setContent(content);
        setShow(true);
        return new Promise(function (resolve) {
            resolver.current = resolve;
        });
    };

    const modalContext: ModalContextType = {
        show: handleShow,
    };

    const handleOk = () => {
        resolver.current && resolver.current(true);
        onHide();
    };

    const handleCancel = () => {
        resolver.current && resolver.current(false);
        onHide();
    };

    return (
        <ConfirmationModalContext.Provider value={modalContext}>
            {props.children}

            {content && (
                <Alert
                    content={content}
                    open={show}
                    handleOk={handleOk}
                    handleCancel={handleCancel}
                />
            )}
        </ConfirmationModalContext.Provider>
    );
};

export type AlertContent = {
    title: string;
    message: string | JSX.Element;
    size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
    confirmBtnLabel?: string;
    customIcon?: React.ReactNode;
};

interface AlertProps {
    content: AlertContent;
    open: boolean;
    handleOk: () => void;
    handleCancel: () => void;
}

const Alert = ({ content, open, handleOk, handleCancel }: AlertProps) => {
    return (
        <Dialog open={open}>
            <DialogContent
                className={cn('max-w-md gap-10 p-10', {
                    [`sm:max-w-${content.size}`]: content.size,
                })}
                hideCloseButton={true}
            >
                <DialogHeader>
                    <DialogTitle>{content.title}</DialogTitle>
                    <DialogDescription>{content.message}</DialogDescription>
                </DialogHeader>
                <DialogFooter>
                    <Button
                        as="div"
                        variant="secondary"
                        size="md"
                        onClick={handleCancel}
                        className="cursor-pointer"
                    >
                        Cancel
                    </Button>
                    <Button
                        as="div"
                        variant="negative"
                        size="md"
                        onClick={handleOk}
                        className="cursor-pointer"
                    >
                        {content.confirmBtnLabel || 'Confirm'}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

const useConfirm = (): ModalContextType =>
    React.useContext(ConfirmationModalContext);

export { ConfirmationModalProvider, useConfirm, useModalShow };
